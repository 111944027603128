import React from "react"

import Layout from "../components/layout"
import GetQuote from "../components/marketing/get-quote"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Get Quote" />
    <GetQuote/>
  </Layout>
)

export default AboutPage
