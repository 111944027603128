import React, { Component } from "react";
// import QuoteForm from './quoteForm'

// import { Link, navigate } from 'gatsby'
import Header from "./header"
class GetQuotepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {/* <Header/>
        <QuoteForm heading="Request a Quote"/> */}
      </div>
    );
  }
}

export default GetQuotepage;
